export default function signInPreselection() {
  const loginModal = $("#loginModal")
  const wrapper = loginModal.add($("#preselection-new"));
  const preSelection = wrapper.find(".tuusula-identify-preselection")
  const emailLogin = wrapper.find(".email-login")
  const strongProviders = wrapper.find(".strong-providers");
  const otherProviders = wrapper.find(".other-providers");
  const backDiv = wrapper.find(".tuusula-identify-back")
  const separator = wrapper.find(".register__separator")
  const identifyStrongButton = wrapper.find("#identify-strong")
  const identifyOtherButton = wrapper.find("#identify-other")
  const backButton = wrapper.find("#back-to-preselection")

  const init = () => {
    emailLogin.hide();
    strongProviders.hide();
    otherProviders.hide();
    backDiv.hide();
    separator.hide();
  }

  identifyStrongButton.on("click", () => {
    preSelection.hide();
    strongProviders.show();
    backDiv.show();
  })

  identifyOtherButton.on("click", () => {
    preSelection.hide();
    otherProviders.show();
    separator.show();
    emailLogin.show();
    backDiv.show();
  })

  backButton.on("click", () => {
    init();
    preSelection.show();
  })

  loginModal.on('closed.zf.reveal', () => {
    init();
    preSelection.show();
  });

  init();
}
